/**
 * Created by gschulz on 08.09.15.
 */
class Navigation {

    constructor() {
        this.container      = $('#navigation');
        this.navigationData = {};
    }

    init() {
        this.container.unbind('click.navClick').on('click.navClick', 'ul.navigation > li a', event => {
            const li   = $(event.currentTarget).parent('li');
            const ul   = li.parent('ul');
            const href = $(event.currentTarget).attr('href');
            if (!href || href.substr(-1) === '#') {
                event.preventDefault();
            } else {
                return;
            }
            const subLi = li.find('li');
            if (!subLi.length) {
                return;
            }
            const subUl = li.find('ul');
            if (!subUl.is(':visible')) {
                //li.addClass('active');
                subUl.hide();
            }
            if (subLi.length === 1) {
                location.href = subLi.find('a').attr('href');
                return;
            }
            if (!subUl.is(':visible')) {
                let otherActive = this.getOpenNavPoints().not(li).not(this.getActiveNavigationPoint());
                otherActive.children('ul').slideUp('fast', () => {
                    //otherActive.removeClass('active');
                })
            }
            subUl.slideToggle('fast', () => {
                if (!subUl.is(':visible') && !li.is(this.getActiveNavigationPoint())) {
                    //li.removeClass('active');
                }
            });
        })
    }

    updateHighlights() {
        const activeLi       = this.container.find('ul.navigation').children('li.active');
        const navigationData = this.getNavigationData();
        $.each(navigationData, (className, opt) => {
            if (!opt.highlight) {
                return;
            }
            if (activeLi.hasClass(className)) {
                opt['highlight'] = false;
                return;
            }
            this.highlight(className, false);
        });
        this.setNavigationData(navigationData);
    }

    getNavigationData() {
        const navigationData = {};
        if (_.isUndefined(sessionStorage['EfbNavigation'])) {
            this.container.find('ul.navigation').children('li').each((i, li) => {
                let classAttr = $(li).attr('class');
                if (!classAttr) {
                    return;
                }
                classAttr = classAttr.split(' ');
                if (classAttr[0] !== 'icon') {
                    return;
                }
                navigationData[classAttr[1]] = {'highlight': false};
            });
            this.setNavigationData(navigationData);
        }
        return $.parseJSON(sessionStorage['EfbNavigation']);
    }

    setNavigationData(navigationData) {
        sessionStorage['EfbNavigation'] = JSON.stringify(navigationData);
        return this;
    }

    highlight(className, toLocalStorage) {
        toLocalStorage    = !_.isUndefined(toLocalStorage) ? toLocalStorage : true;
        const highlightLi = this.container.find('ul.navigation').children('li.' + className);
        if (!highlightLi.length) {
            return;
        }
        highlightLi.addClass('highlight');
        if (!toLocalStorage) {
            return;
        }
        const navigationData = this.getNavigationData();
        if (_.isUndefined(navigationData[className])) {
            navigationData[className] = {};
        }
        navigationData[className]['highlight'] = true;
        this.setNavigationData(navigationData);
    }

    getActiveNavigationPoint() {
        if (this.activeNavigationPoint) {
            return this.activeNavigationPoint;
        }
        this.container.find('ul.navigation > li').each((i, li) => {
            li = $(li);
            if (li.find('a.active').length) {
                this.activeNavigationPoint = li;
                return false;
            }
            return true;
        });
        return this.activeNavigationPoint || $();
    }

    getOpenNavPoints() {
        return this.container.find('ul.navigation > li > ul:visible').parent('li')
    }
}